import { useReducer } from "react";
import { File } from "./file";
import { parseFlightPlan, Waypoint } from "./flightplan";
import { error, ok, Result } from "./result";

export interface FlightPlan {
    id: string;
    name: string;
    waypoints: Waypoint[];
}

interface State {
    flightplan: Result<FlightPlan, string> | undefined;
}

function clearedFlightPlan(prevState: State): State {
    return { ...prevState, flightplan: undefined };
}

function loadedFlightPlan(prevState: State, planFile: File): State {
    try {
        const waypoints = parseFlightPlan(planFile.content);
        return {
            ...prevState,
            flightplan: ok({
                id: planFile.fileName,
                name: planFile.fileName.replace(/\..+$/, ""),
                waypoints
            })
        };
    } catch (e) {
        return { ...prevState, flightplan: error("" + e) };
    }
}

function changedFlightPlanName(prevState: State, name: string): State {
    if (prevState.flightplan == null || prevState.flightplan.status !== "ok") {
        return prevState;
    }

    return { ...prevState, flightplan: ok({ ...prevState.flightplan.data, name: name }) };
}

function changedWaypoint(prevState: State, wpt: Waypoint, updated: Waypoint): State {
    if (prevState.flightplan?.status !== "ok" || wpt === updated) {
        return prevState;
    }

    console.log("change", wpt, "to", wpt);

    const waypoints = [...prevState.flightplan.data.waypoints];
    const i = waypoints.indexOf(wpt);
    waypoints[i] = updated;
    return { ...prevState, flightplan: ok({ ...prevState.flightplan.data, waypoints }) };
}

const actionTable = {
    clearedFlightPlan,
    loadedFlightPlan,
    changedFlightPlanName,
    changedWaypoint
} as const;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type RemoveFirstFromTuple<T extends any[]> = T["length"] extends 0
    ? never
    : // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ((...b: T) => void) extends (a: any, ...b: infer I) => void
    ? I
    : [];

type OneAction<K extends keyof typeof actionTable> = {
    type: K;
    args: RemoveFirstFromTuple<Parameters<typeof actionTable[K]>>;
};

type Action =
    | OneAction<"loadedFlightPlan">
    | OneAction<"changedFlightPlanName">
    | OneAction<"clearedFlightPlan">
    | OneAction<"changedWaypoint">;

function reducer(prevState: State, action: Action): State {
    const fn = actionTable[action.type];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (fn as any).apply({}, [prevState, ...action.args]);
}

type Dispatchers = {
    [K in keyof typeof actionTable]: (...args: RemoveFirstFromTuple<Parameters<typeof actionTable[K]>>) => void;
};

export function useStateReducer(): [State, Dispatchers] {
    const [state, dispatch] = useReducer(reducer, { flightplan: undefined });
    const dispatchers = Object.fromEntries(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        Object.keys(actionTable).map((type) => [type, (...args: unknown[]) => dispatch({ type, args } as any)])
    ) as Dispatchers;

    return [state, dispatchers];
}
