interface Coord3D {
    latitude: number;
    longitude: number;
    altitude: number;
}

const degMinSecRegex = /(?<Deg>\d+). (?<Min>\d+)' (?<Sec>\d+(\.\d+)?)"/;
function parseDegMinSec(s: string): number | null {
    const m = s.replace(",", ".").match(degMinSecRegex);
    if (m == null || m.groups == null) {
        return null;
    }

    const deg = +m.groups["Deg"];
    const min = +m.groups["Min"];
    const sec = +m.groups["Sec"];

    return deg + min / 60 + sec / 3600;
}

const worldPosRegex = /(?<LatNS>[NS])(?<Lat>[^,]+),(?<LonEW>[EW])(?<Lon>[^,]+),(?<Alt>[-+]\d+)/;
function parseCoord3D(s: string): Coord3D | null {
    const m = s.match(worldPosRegex);
    if (m == null || m.groups == null) {
        return null;
    }

    const lat = parseDegMinSec(m.groups["Lat"]);
    const lon = parseDegMinSec(m.groups["Lon"]);
    const alt = +m.groups["Alt"];
    if (lat == null || lon == null) {
        return null;
    }

    return {
        latitude: (m.groups["LatNS"] === "N" ? 1 : -1) * lat,
        longitude: (m.groups["LonEW"] === "E" ? 1 : -1) * lon,
        altitude: alt
    };
}

export interface Waypoint {
    name: string;
    position: Coord3D;
    selected: boolean;
}

export function parseFlightPlan(s: string): Waypoint[] {
    const parser = new DOMParser();
    const doc = parser.parseFromString(s, "application/xml");
    const errorNode = doc.querySelector("parsererror");
    if (errorNode) {
        throw new FlightPlanParseError(`Unable to parse flightplan file: ${errorNode.textContent}`);
    } else {
        console.log(doc.documentElement.nodeName);
        const it = doc.evaluate("//ATCWaypoint", doc, null, XPathResult.ORDERED_NODE_ITERATOR_TYPE, null);

        try {
            const newWpts: Waypoint[] = [];
            let thisNode = it.iterateNext();
            while (thisNode) {
                if (thisNode instanceof Element) {
                    const name = thisNode.getAttribute("id") ?? "<unknown>";
                    const position = parseCoord3D(thisNode.getElementsByTagName("WorldPosition")[0].textContent ?? "");
                    if (name && position) {
                        newWpts.push({ name, position, selected: true });
                    }
                }
                thisNode = it.iterateNext();
            }

            return newWpts;
        } catch (e) {
            throw new FlightPlanParseError(`Unable to parse flightplan file: ${e}`);
        }
    }
}

class FlightPlanParseError extends Error {
    constructor(message: string) {
        super(message);
        this.name = "FlightPlanParseError";
    }
}
