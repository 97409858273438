import React from "react";
import { File } from "./file";

interface FileUploadProps {
    file: File | undefined;
    onChange(f: File | undefined): void;
}

export function FileUpload({ onChange }: FileUploadProps) {
    return (
        <input
            type="file"
            onChange={(e) => {
                const files = e.currentTarget.files;
                if (files == null) {
                    onChange(undefined);
                    return;
                }

                const newFile = files[0];
                newFile.text().then((content) => onChange({ fileName: newFile.name, content }));
            }}
        />
    );
}
