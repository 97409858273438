import JSZip from "jszip";
import React, { useState } from "react";
import { File } from "./file";
import { FileUpload } from "./FileUpload";
import { Waypoint } from "./flightplan";
import { FlightPlan, useStateReducer } from "./state";

interface WaypointRowProps {
    waypoint: Waypoint;
    onChange: (waypoint: Waypoint) => void;
}

function WaypointRow({ waypoint, onChange }: WaypointRowProps) {
    return (
        <tr>
            <td>
                <input
                    type="checkbox"
                    checked={waypoint.selected}
                    onChange={(e) => onChange({ ...waypoint, selected: e.currentTarget.checked })}
                />
            </td>
            <td>
                <input
                    type="text"
                    value={waypoint.name}
                    onChange={(e) => onChange({ ...waypoint, name: e.currentTarget.value })}
                />
            </td>
            <td>{waypoint.position.latitude.toFixed(6)}</td>
            <td>{waypoint.position.longitude.toFixed(6)}</td>
            <td>
                <input
                    type="number"
                    value={waypoint.position.altitude}
                    onChange={(e) =>
                        onChange({
                            ...waypoint,
                            position: { ...waypoint.position, altitude: e.currentTarget.valueAsNumber }
                        })
                    }
                />
            </td>
        </tr>
    );
}

interface FlightPlan2Props {
    plan: FlightPlan;
    onNameChange: (newName: string) => void;
    onWaypointChange: (waypoint: Waypoint, newWaypoint: Waypoint) => void;
}

function FlightPlan2({ plan, onNameChange, onWaypointChange }: FlightPlan2Props) {
    return (
        <div>
            <input type="text" value={plan.name} onChange={(e) => onNameChange(e.currentTarget.value)} />
            <table>
                <thead>
                    <tr>
                        <td></td>
                        <td>Name</td>
                        <td>Latitude</td>
                        <td>Longitude</td>
                        <td>Altitude</td>
                    </tr>
                </thead>
                <tbody>
                    {plan.waypoints.map((w, i) => (
                        <WaypointRow key={i} waypoint={w} onChange={(n) => onWaypointChange(w, n)} />
                    ))}
                </tbody>
            </table>
        </div>
    );
}

interface ExportProps {
    plan: FlightPlan;
}

function chunk<T>(array: T[], length: number): T[][] {
    const result = [];
    let current = [];
    for (let i = 0; i < array.length; i++) {
        if (current.length >= length) {
            result.push(current);
            current = [];
        }
        current.push(array[i]);
    }
    result.push(current);
    return result;
}

function Ln3Export({ plan }: ExportProps) {
    const files = chunk(
        plan.waypoints.filter((w) => w.selected),
        12
    );
    const ln3Data = files.map((wpts, i) => {
        const title = `${plan.name} part ${i + 1} (${wpts[0].name}-${wpts[wpts.length - 1].name})`;
        const filename = title.replaceAll(" ", "_") + ".ln3";
        const content = getLn3(title, wpts);
        return { title, filename, content };
    });

    return (
        <div>
            <ul>
                {ln3Data.map((x) => (
                    <li key={x.title}>
                        <a
                            href={x.filename}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                download(x.filename, x.content);
                            }}
                        >
                            {x.title}
                        </a>
                    </li>
                ))}
            </ul>
            <a
                href={plan.name.replace(" ", "_") + ".zip"}
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    const zip = new JSZip();
                    ln3Data.forEach((x) => zip.file(x.filename, x.content));
                    zip.generateAsync({ type: "blob" }).then((content) =>
                        download(plan.name.replace(" ", "_") + ".zip", content)
                    );
                }}
            >
                All parts (.zip)
            </a>
        </div>
    );
}

function Export({ plan }: ExportProps) {
    return (
        <div>
            Format:
            <select>
                <option id="ln3">.ln3</option>
            </select>
            <Ln3Export plan={plan} />
        </div>
    );
}

function getLn3(title: string, waypoints: Waypoint[]): string {
    const stations = waypoints.map(
        (w, i) => `[STATION${i + 1}]
{
LAT: ${w.position.latitude.toFixed(6)};
LON: ${w.position.longitude.toFixed(6)};
NAME: "${w.name}";
ALT: ${w.position.altitude.toFixed(0)};
}`
    );
    return `#
${title}
#

${stations.join("\n\n")}
`;
}

function download(fileName: string, content: string | Blob) {
    const elem = document.createElement("a");
    let dataUrl: string | null = null;
    if (typeof content === "string") {
        elem.href = "data:text/plain;charset=utf-8," + encodeURIComponent(content);
    } else {
        dataUrl = URL.createObjectURL(content);
        elem.href = dataUrl;
    }

    elem.download = fileName;
    elem.style.opacity = "0";
    elem.style.width = "0";
    elem.style.height = "0";
    elem.style.position = "absolute";
    document.body.appendChild(elem);
    elem.click();
    document.body.removeChild(elem);
    if (dataUrl != null) {
        URL.revokeObjectURL(dataUrl);
    }
}

export function App() {
    const [currentFile, setCurrentFile] = useState<File | undefined>(undefined);
    const [state, dispatchers] = useStateReducer();

    return (
        <div>
            <h1>PlanConverter</h1>
            <FileUpload
                file={currentFile}
                onChange={(f) => {
                    if (f == null) {
                        dispatchers.clearedFlightPlan();
                    } else {
                        dispatchers.loadedFlightPlan(f);
                    }
                    setCurrentFile(f);
                }}
            />
            <hr />
            {state.flightplan?.status === "ok" && (
                <>
                    <FlightPlan2
                        plan={state.flightplan.data}
                        onNameChange={dispatchers.changedFlightPlanName}
                        onWaypointChange={dispatchers.changedWaypoint}
                    />
                    <Export plan={state.flightplan.data} />
                </>
            )}
        </div>
    );
}
